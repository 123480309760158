import { gql } from "@apollo/client";

const datasetEntries = gql`
  query getDatasetEntries($datasetId: String!, $grader: Grader) {
    datasetEntries(datasetId: $datasetId, grader: $grader) {
      formattedName
      id
    }
  }
`;

export default datasetEntries;