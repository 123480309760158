import loadable from '@loadable/component';
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Page from '../components/pages/Page';
import { Path } from '../types/routes';

const EarlyAccess = loadable(() => import('../pages/EarlyAccess'));
const Login = loadable(() => import('../pages/Login'));
const SignUp = loadable(() => import('../pages/SignUp'));

const SignUpRouter: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <Page>
        {children}
        <Routes>
          <Route
            path={Path.HOME}
            element={<Login />}
          />
          <Route
            path={`${Path.EARLY_ACCESS}`}
            element={<EarlyAccess />}
          />
          <Route
            path={`${Path.SIGNUP}/*`}
            element={<SignUp />}
          />
          <Route
            path="*"
            element={<Navigate to={Path.HOME} replace />}
          />
        </Routes>
      </Page>
    </BrowserRouter>
  );
}

export default SignUpRouter;