import loadable from '@loadable/component';
import BubbleChartTwoToneIcon from '@mui/icons-material/BubbleChartTwoTone';
import CompareTwoToneIcon from '@mui/icons-material/CompareTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FaceTwoToneIcon from '@mui/icons-material/FaceTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import { Page } from '../types/pages';
import { Path } from '../types/routes';
import PageLoading from '../components/pages/PageLoading';

const pages: Page[] = [{
  childRoutes: [':datasetId', ':datasetId/:entryId'],
  Component: loadable(() => import('./Data/Sets')),
  Icon: StyleTwoToneIcon,
  name: 'Sets',
  parseSelection: true,
  path: Path.SETS,
}, {
  childRoutes: [':pokemonId', ':pokemonId/:entryId'],
  Component: loadable(() => import('./Data/Pokemon')),
  Icon: FaceTwoToneIcon,
  name: 'Pokémon',
  parseSelection: true,
  path: Path.POKEMON,
}, {
  Component: loadable(() => import('./Data/Stats')),
  Icon: BubbleChartTwoToneIcon,
  name: 'Statistics',
  parseSelection: true,
  path: Path.STATISTICS,
  shortName: 'Stats'
}, {
  childRoutes: [':page', ':page/:list'],
  Component: loadable(() => import('./Data/Rankings')),
  Icon: EmojiEventsTwoToneIcon,
  name: 'Rankings',
  parseSelection: true,
  path: Path.RANKINGS,
  shortName: 'Ranks',
}, {
  Component: loadable(() => import('./Compare')),
  disabled: true,
  Icon: CompareTwoToneIcon,
  name: 'Compare',
  path: Path.COMPARE,
}, {
  Component: loadable(() => import('./Watch')),
  disabled: true,
  Icon: LibraryBooksTwoToneIcon,
  name: 'Watch',
  path: Path.WATCH,
}];

export default pages;