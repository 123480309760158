import { gql } from "@apollo/client";

const datasets = gql`
  query getDatasets($grader: Grader) {
    datasets(grader: $grader) {
      id
      formattedName
      formattedYear
      language
    }
  }
`;

export default datasets;