import { gql } from "@apollo/client";

const datasetEntryGradeHistory = gql`
  query getDatasetEntryGradeHistory($datasetEntryId: String!, $grader: Grader, $parseId: Int) {
    datasetEntryGradeHistory(datasetEntryId: $datasetEntryId, grader: $grader, parseId: $parseId) {
      date
      histogram {
        data {
          type
          value
        }
        date
        weeksBack
      }
      history {
        total {
          type
          value
        }
        weeksBack
        weeksCappedAt
      }
      id
      total {
        type
        value
      }
    }
  }
`;

export default datasetEntryGradeHistory;