import { gql } from "@apollo/client";

const latestParse = gql`
  query getLatestParse($grader: Grader) {
    latestParse(grader: $grader) {
      id
      date
    }
  }
`;

export default latestParse;