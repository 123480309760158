import React from 'react';

export enum Grader {
  PSA = "PSA",
  CGC = "CGC",
}

const GraderContext = React.createContext<{
  grader: Grader
  getGraderRoute: (route: string) => string
  setGrader: (grader: Grader) => void
}>({
  grader: Grader.PSA,
  getGraderRoute: () => '',
  setGrader: () => {},
});

export default GraderContext;