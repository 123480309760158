import React from 'react';
import { getAuth, onAuthStateChanged, signOut, User as FirebaseUser } from "firebase/auth";
import UserContext from "../context/user";
import { UserAccess, UserStatus } from '../types/user';
import { Path } from '../types/routes';


const UserProvider: React.FC = ({ children }) => {
  const [data, updateUserData] = React.useState<FirebaseUser | undefined>(undefined);
  const [access, setUserAccess] = React.useState<UserAccess>(UserAccess.None);
  const [status, setUserStatus] = React.useState<UserStatus>(UserStatus.NotReady);

  React.useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        updateUserStatus(UserStatus.Guest);
        updateUserAccess(UserAccess.None);
        updateUserData(undefined);
        return;
      }

      user.getIdToken().then(token => {
        updateUserData(user);
        updateUserStatus(UserStatus.NotReady);
      });
    });
  }, []);

  const updateUserAccess = (access: UserAccess) => {
    setUserAccess(access);
  }

  const updateUserStatus = (status: UserStatus) => {
    setUserStatus(status);
  }

  const onSignOut = () => {
    if (status === UserStatus.Guest || status === UserStatus.NotReady) {
      return;
    }

    updateUserStatus(UserStatus.NotReady);

    const auth = getAuth();
    signOut(auth).then(() => {
      window.location.pathname = Path.HOME;
      updateUserData(undefined);
      updateUserAccess(UserAccess.None);
      updateUserStatus(UserStatus.Guest);
    });
  }

  return (
    <UserContext.Provider value={{
      user: {
        access,
        data,
        status,
      },
      updateUserAccess,
      updateUserStatus,
      signOut: onSignOut,
    }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;