import React from 'react';
import { Fab } from '@mui/material';
import GraderContext, { Grader } from '../../context/grader';
import GraderLogo from '../GraderLogo';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';

const GraderToggle = () => {
  const { grader, setGrader } = React.useContext(GraderContext);

  const handleGraderChange = () => {
    const graders = Object.values(Grader);
    // This grabs the next grader in the list (reverting to 0 if at end).
    setGrader(graders[(graders.indexOf(grader) + 1) % graders.length]);
  }

  return (
    <>
      <Fab
        onClick={handleGraderChange}
        size="large"
        sx={{
          bottom: (theme) => theme.spacing(2),
          background: (theme) => theme.palette.common.white,
          position: 'fixed',
          right: (theme) => theme.spacing(2),
        }}
        variant="extended"
      >
        <CachedTwoToneIcon sx={{ mr: 1 }} />
        <GraderLogo grader={grader} />
      </Fab>
    </>
  );
}

export default GraderToggle;