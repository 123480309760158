import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { AppBar } from '@mui/material';
import { Path } from '../types/routes';

import UserContext from '../context/user';
import { UserStatus } from '../types/user';
import Page from '../components/pages/Page';
import { Grader } from '../context/grader';
import loadable, { LoadableComponent } from '@loadable/component';

const CheckUserLoggedIn: React.FC = ({ children }) => {
  const { user } = React.useContext(UserContext);

  if (!user.data || user.status !== UserStatus.Online) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

const Account = loadable(() => import('../pages/Account'));
const EarlyAccess = loadable(() => import('../pages/EarlyAccess'));
const Home = loadable(() => import('../pages/Home'));
const SignUp = loadable(() => import('../pages/SignUp'));
const GraderRoutes = loadable(() => import('./GraderRoutes'));

const PrimaryRouter: React.FC = ({ children }) => {
  const withAppBar = (element: JSX.Element | LoadableComponent<any>) => {
    return (
      <CheckUserLoggedIn>
        {children}
        <AppBar position="static" sx={{ visibility: 'hidden' }} />
        {element}
      </CheckUserLoggedIn>
    );
  }

  return (
    <BrowserRouter>
      <Page sx={{
        paddingBottom: (theme) => theme.spacing(10), // This ensures there's enough height on the page for the floating GraderToggle component.
        zIndex: (theme) => theme.zIndex.appBar - 1,
      }}>
        <Routes>
          <Route
            path={`${Path.ACCOUNT}/*`}
            element={withAppBar(<Account />)}
          />
          <Route
            path={Path.HOME}
            element={withAppBar(<Home />)}
          />
          <Route
            path={`${Path.EARLY_ACCESS}`}
            element={withAppBar(<EarlyAccess />)}
          />
          <Route
            path={Path.SIGNUP}
            element={<SignUp />}
          />
          {Object.values(Grader).map(grader => (
            <Route
              key={grader}
              path={`${grader}/*`}
              element={withAppBar(<GraderRoutes />)}
            />
          ))}
        </Routes>
      </Page>
    </BrowserRouter>
  )
}

export default PrimaryRouter;