import { gql } from "@apollo/client";

const datasetGradeHistory = gql`
  query getDatasetGradeHistory($datasetId: String!, $grader: Grader, $parseId: Int, $variant: String) {
    datasetGradeHistory(datasetId: $datasetId, grader: $grader, parseId: $parseId, variant: $variant) {
      date
      histogram {
        data {
          type
          value
        }
        date
        weeksBack
      }
      history {
        total {
          type
          value
        }
        weeksBack
        weeksCappedAt
      }
      id
      total {
        type
        value
      }
      variants
    }
  }
`;

export default datasetGradeHistory;