export enum Path {
  ACCOUNT = '/account',
  COMPARE = '/compare',
  EARLY_ACCESS = '/early-access',
  HOME = '/',
  LOGIN = '/login',
  POKEMON = '/pokemon',
  RANKINGS = '/rankings',
  SETS = '/sets',
  SIGNUP = '/join',
  STATISTICS = '/stats',
  WATCH = '/watch',
}