import { gql } from "@apollo/client";

const datasetsRankingYearly = gql`
  query getDatasetsRankingYearly($grader: Grader, $page: Int, $parseId: Int) {
    datasetsRankingYearly(grader: $grader, page: $page, parseId: $parseId) {
      data {
        change
        formattedName
        id
        language
        position
        value
      }
      date
      pagination {
        page
        pages
        prev
        next
      }
    }
  }
`;

export default datasetsRankingYearly;