import React from 'react';
import { Link as ReactRouterLink, NavLink as ReactRouterNavLink } from 'react-router-dom';
import {
  useLazyQuery,
} from "@apollo/client";
import { AppBar, Box, Container, Drawer, IconButton, List, ListItem, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import User from './components/navigation/User';
import { LATEST_PARSE } from './graphql';
import { Link } from '@mui/material';
import { Path } from './types/routes';
import DarkModeContext from './context/darkMode';
import DataContext from './context/data';
import pages from './pages/pages';
import ResponseError from './components/data/ResponseError';
import AppLoading from './components/AppLoading';
import GraderContext, { Grader } from './context/grader';
import { Parse } from './types/data';

const App = () => {
  const theme = useTheme();
  const isSmallScreenDisplay = useMediaQuery(theme.breakpoints.down('md'));

  const { isDarkModeEnabled } = React.useContext(DarkModeContext);
  const { addData, data } = React.useContext(DataContext);
  const { getGraderRoute, grader } = React.useContext(GraderContext);
  const [getLatestParse, { loading, error, data: response }] = useLazyQuery(LATEST_PARSE);
  const [isSmallScreenDrawerOpen, setSmallScreenDrawerOpen] = React.useState<boolean>(false);
  const [latestParse, setLatestParse] = React.useState<Parse>();

  const dataKey = grader === Grader.PSA ? 'LATEST_PARSE' : 'CGC_LATEST_PARSE';
  const dataResponseKey = 'latestParse';

  React.useEffect(() => {
    if (data && data[dataKey]) {
      setLatestParse(data[dataKey]);
      return;
    }

    getLatestParse({
      variables: { grader }
    });
  }, [grader]);

  React.useEffect(() => {
    if (!response || !response[dataResponseKey]) {
      return;
    }

    const {
      [dataResponseKey]: data,
    } = response;

    addData(dataKey, data);
    setLatestParse(data);
  }, [response]);

  if (loading || !latestParse) {
    return <AppLoading />;
  }

  if (error) {
    return (
      <Box sx={{
        alignItems: 'center',
        background: (theme) => theme.palette.grey[isDarkModeEnabled ? '800' : '100'],
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100%',
      }}>
        <ResponseError />
      </Box>
    );
  }

  const homeLink = (
    <Link
      component={ReactRouterLink}
      sx={{
        color: theme => theme.palette.common[isDarkModeEnabled ? 'white' : 'black'],
        lineHeight: (theme) => theme.spacing(2.5),
      }}
      to={Path.HOME}
    >
      Poké
      <br/>
      Metrics
    </Link>
  );

  const links = (
    <List component="div" sx={isSmallScreenDisplay ? undefined : {
      display: 'block',
      padding: 0,
    }}>
      {pages.map(({
        disabled,
        Icon,
        name,
        path,
        shortName,
      }) => (
        <ListItem
          button
          component={ReactRouterNavLink}
          to={getGraderRoute(path)}
          key={`${getGraderRoute(path)}-nav`}
          sx={isSmallScreenDisplay ? undefined : {
            borderBottomColor: 'transparent',
            borderBottomStyle: 'solid',
            borderBottomWidth: 0,
            borderTopColor: 'transparent',
            borderTopStyle: 'solid',
            borderTopWidth: 0,
            display: 'inline-block',
            width: 'auto',

            '&.active': {
              borderBottomColor: theme => theme.palette.primary.dark,
              borderBottomStyle: 'solid',
              borderBottomWidth: 3,
              borderTopWidth: 3,
              color: theme => theme.palette.primary.dark,

              '& .MuiTypography-root': {
                fontWeight: theme => theme.typography.fontWeightMedium,
              },
            },
          }}
          disabled={disabled}
        >
          <Typography sx={isSmallScreenDisplay ? undefined : {
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}>
            <Icon sx={{
              marginRight: 1,
            }} />
            {shortName || name}
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{
      flexGrow: 1,
      position: 'relative',
      zIndex: (theme) => theme.zIndex.appBar,
    }}>
      <AppBar position="static" color="transparent" sx={{
        background: theme => theme.palette.common[isDarkModeEnabled ? 'black' : 'white'],
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: theme => theme.palette.common[isDarkModeEnabled ? 'white' : 'black'],
      }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{
              display: {
                'xs': 'flex',
                'md': 'none',
              },
              flexGrow: 1,
            }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setSmallScreenDrawerOpen(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                onClick={() => setSmallScreenDrawerOpen(false)}
                onClose={() => setSmallScreenDrawerOpen(false)}
                open={isSmallScreenDrawerOpen}
                sx={{
                  '.MuiDrawer-paper': {
                    width: 240,
                  }
                }}
              >
                <Typography variant="h6" component="h2" sx={{
                  pl: 2,
                  pr: 2,
                  pt: 2,
                }}>
                  PokéMetrics
                </Typography>
                {links}
              </Drawer>
              <Typography variant="h6" component="div" sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1
              }}>
                {homeLink}
              </Typography>
            </Box>
            <Box sx={{
              alignItems: 'center',
              display: {
                'xs': 'none',
                'md': 'flex',
              },
              flexGrow: 1
            }}>
              <Typography variant="h6" component="div" sx={{
                lineHeight: (theme) => theme.spacing(2),
                mr: 3,
              }}>
                {homeLink}
              </Typography>
              {links}
            </Box>
            <User />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default App;
