import { gql } from "@apollo/client";

const allParses = gql`
  query getAllParses($grader: Grader) {
    allParses(grader: $grader) {
      id
      date
    }
  }
`;

export default allParses;