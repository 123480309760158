import { User as FirebaseUser } from "firebase/auth";

export interface User {
  access?: UserAccess
  data?: FirebaseUser
  status: UserStatus
}

export enum UserAccess {
  CGC,
  Complete,
  None,
  PSA,
}

export enum UserStatus {
  Guest,          // The user is not logged in.
  NotReady,       // Before the page is ready.
  NotSubscribed,  // The user is logged in but not subscribed.
  Online,         // The user is logged in.
}