import { useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';
import DarkModeContext from '../../context/darkMode';
import UserContext from '../../context/user';
import { USER } from '../../graphql';
import { UserAccess, UserStatus } from '../../types/user';
import AppLoading from '../AppLoading';
import ResponseError from '../data/ResponseError';

const CheckUserAccess = () => {
  const { isDarkModeEnabled } = React.useContext(DarkModeContext);
  const { user, updateUserAccess, updateUserStatus } = React.useContext(UserContext);
  const [getUser, { loading, error, data: response }] = useLazyQuery(USER);

  const dataResponseKey = 'user';

  React.useEffect(() => {
    if (!user.data || user.status !== UserStatus.NotReady) {
      return;
    }

    getUser();
  }, [user]);

  React.useEffect(() => {
    if (!response || !response[dataResponseKey]) {
      return;
    }

    const {
      [dataResponseKey]: userData,
    } = response;

    if (userData.hasActiveMembership) {
      const access = (userData.access || UserAccess.None) as UserAccess;

      updateUserAccess(access);
      updateUserStatus(UserStatus.Online);
      return;
    }

    updateUserStatus(UserStatus.NotSubscribed);
  }, [response]);

  if (error) {
    return (
      <Box sx={{
        alignItems: 'center',
        background: (theme) => theme.palette.grey[isDarkModeEnabled ? '800' : '100'],
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100%',
      }}>
        <ResponseError />
      </Box>
    );
  }

  return <AppLoading />
}

export default CheckUserAccess;