import React from 'react';
import GraderContext from '../context/grader';
import { Grader } from '../context/grader';

const GraderProvider: React.FC = ({ children }) => {
  const [selectedGrader, setSelectedGrader] = React.useState<Grader>(
    (localStorage.getItem('grader') as Grader) ?? Grader.PSA
  );

  React.useEffect(() => {
    if (!(selectedGrader in Grader)) {
      console.error(`Unhandled grader "${selectedGrader}" detected. Defaulting to ${Grader.PSA}.`);
      setSelectedGrader(Grader.PSA);
      return;
    }
    
    localStorage.setItem('grader', selectedGrader);
  }, [selectedGrader]);

  const grader = React.useMemo(
    () => ({
      getGraderRoute: (route: string) => `/${selectedGrader.toLowerCase()}${route}`,
      grader: selectedGrader,
      setGrader: (grader: Grader) => {
        setSelectedGrader(grader ?? Grader.PSA);
      },
    }),
    [selectedGrader],
  );

  return (
    <GraderContext.Provider value={grader}>
      {children}
    </GraderContext.Provider>
  )
}

export default GraderProvider;