import React from 'react';
import DataContext from '../context/data';
import { Data } from '../types/data';

const DataProvider: React.FC = ({ children }) => {
  const [data, setData] = React.useState<Data>({});

  const onAddData = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  }

  return (
    <DataContext.Provider value={{
      addData: onAddData,
      data,
    }}>
      {children}
    </DataContext.Provider>
  )
}

export default DataProvider;