import { gql } from "@apollo/client";

const pokemonRankingWeekly = gql`
  query getPokemonRankingWeekly($grader: Grader, $page: Int, $parseId: Int) {
    pokemonRankingWeekly(grader: $grader, page: $page, parseId: $parseId) {
      data {
        change
        id
        name
        note
        position
        value
      }
      date
      pagination {
        page
        pages
        prev
        next
      }
    }
  }
`;

export default pokemonRankingWeekly;