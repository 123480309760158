import { gql } from "@apollo/client";

const pokemonEntries = gql`
  query getPokemonEntries($grader: Grader, $pokemonId: Int!) {
    pokemonEntries(grader: $grader, pokemonId: $pokemonId) {
      dataset {
        id
        language
        formattedName
      }
      formattedName
      id
    }
  }
`;

export default pokemonEntries;