import { gql } from "@apollo/client";

const pokemon = gql`
  query getPokemon($grader: Grader) {
    pokemon(grader: $grader) {
      id
      name
      note
    }
  }
`;

export default pokemon;