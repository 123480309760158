import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import DarkModeContext from '../context/darkMode';

const AppLoading = () => {
  const { isDarkModeEnabled } = React.useContext(DarkModeContext);

  return (
    <Box sx={{
      alignItems: 'center',
      background: (theme) => theme.palette.grey[isDarkModeEnabled ? '800' : '100'],
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      width: '100%',
    }}>
      <CircularProgress />
    </Box>
  );
}

export default AppLoading;