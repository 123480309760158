import React from 'react';
import { Box, Typography } from '@mui/material';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import DarkModeContext from '../../context/darkMode';

const ResponseError = () => {
  const { isDarkModeEnabled } = React.useContext(DarkModeContext);

  return (
    <Box sx={{
      color: (theme) => theme.palette.common[isDarkModeEnabled ? 'white' : 'black'],
      textAlign: 'center',
    }}>
      <AnnouncementTwoToneIcon sx={{
        fontSize: 64,
      }} />
      <Typography>
        An error has occurred.
      </Typography>
      <Typography sx={{
        mb: 2,
      }}>
        Please reload the page and try again.
      </Typography>
      <Typography variant="body2">
        If this keeps happening, please email support@pokemetrics.org.
      </Typography>
    </Box>
  );
}

export default ResponseError;