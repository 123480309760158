import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context'
import UserProvider from './providers/UserProvider';
import { PrimaryRouter, SignUpRouter } from './routers';
import DarkModeProvider from './providers/DarkModeProvider';
import DataProvider from './providers/DataProvider';
import GraderProvider from './providers/GraderProvider';
import UserContext from './context/user';
import { UserStatus } from './types/user';
import App from './App';
import GraderToggle from './components/navigation/GraderToggle';
import CheckUserAccess from './components/user/CheckUserAccess';
import firebase, { firebaseAuth } from './firebase';

const authLink = setContext(async (_, { headers }) => {
  const user = firebase.auth().currentUser;

  if (!user) {
    throw new Error('Not logged in.');
  }

  const token = await user.getIdToken();

  if (!token) {
    return {
      headers,
    };
  }

  return{
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GatewaySwitch = () => {
  const { user } = React.useContext(UserContext);

  if (user.data && user.status === UserStatus.Online) {
    return (
      <DarkModeProvider>
        <ApolloProvider client={client}>
          <DataProvider>
            <GraderProvider>
              <PrimaryRouter>
                <App />
                <GraderToggle />
              </PrimaryRouter>
            </GraderProvider>
          </DataProvider>
        </ApolloProvider>
      </DarkModeProvider>
    );
  }

  if (user.status === UserStatus.NotReady) {
    return (
      <DarkModeProvider>
        <ApolloProvider client={client}>
          <CheckUserAccess />
        </ApolloProvider>
      </DarkModeProvider>
    );
  }

  if (user.status === UserStatus.NotSubscribed) {
    return (
      <ApolloProvider client={client}>
        <SignUpRouter />
      </ApolloProvider>
    )
  }

  return (
    <SignUpRouter />
  );
}

const Gateway = () => (
  <UserProvider>
    <GatewaySwitch />
  </UserProvider>
);

export default Gateway;
