import { gql } from "@apollo/client";

const datasetEntriesRankingMostGemMint = gql`
  query getDatasetEntriesRankingMostGemMint($datasetId: String, $grader: Grader, $page: Int, $parseId: Int) {
    datasetEntriesRankingMostGemMint(datasetId: $datasetId, grader: $grader, page: $page, parseId: $parseId) {
      data {
        change
        dataset {
          formattedName
          id
          language
        }
        datasetChange
        datasetPosition
        formattedName
        id
        position
        value
      }
      date
      pagination {
        page
        pages
        prev
        next
      }
    }
  }
`;

export default datasetEntriesRankingMostGemMint;