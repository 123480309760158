import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import DarkModeContext from '../context/darkMode';

const DarkModeProvider: React.FC = ({ children }) => {
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    (localStorage.getItem('mode') as 'light' | 'dark') ?? 'light'
  );

  React.useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  const colorMode = React.useMemo(
    () => ({
      isDarkModeEnabled: mode === 'dark',
      toggleDarkMode: (isEnabled: boolean) => {
        setMode(isEnabled ? 'dark' : 'light');
      },
    }),
    [mode],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <DarkModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </DarkModeContext.Provider>
  )
}

export default DarkModeProvider;