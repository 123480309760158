import React from 'react';
import { Data } from '../types/data';

const DataContext = React.createContext<{
  addData: (key: string, value: any) => void,
  data: Data,
}>({
  addData: () => {},
  data: {},
});

export default DataContext;