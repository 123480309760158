import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const config = {
  apiKey: "AIzaSyCs24OjDYc7xLXxeBTYXu6cUAWMBYUVHoE",
  authDomain: "pokemetrics.firebaseapp.com",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const firebaseAuth = firebase.auth();

export default firebase;