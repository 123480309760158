import { gql } from "@apollo/client";

const datasetsRankingOverall = gql`
  query getDatasetsRankingOverall($grader: Grader, $page: Int, $parseId: Int) {
    datasetsRankingOverall(grader: $grader, page: $page, parseId: $parseId) {
      data {
        change
        formattedName
        id
        language
        position
        value
      }
      date
      pagination {
        page
        pages
        prev
        next
      }
    }
  }
`;

export default datasetsRankingOverall;