import { gql } from "@apollo/client";

const pokemonGradeHistory = gql`
  query getPokemonGradeHistory($grader: Grader, $parseId: Int, $pokemonId: Int!) {
    pokemonGradeHistory(grader: $grader, parseId: $parseId, pokemonId: $pokemonId) {
      date
      histogram {
        data {
          type
          value
        }
        date
        weeksBack
      }
      history {
        total {
          type
          value
        }
        weeksBack
        weeksCappedAt
      }
      id
      total {
        type
        value
      }
    }
  }
`;

export default pokemonGradeHistory;