import { gql } from "@apollo/client";

const user = gql`
  query getUser {
    user {
      access
      hasActiveMembership
    }
  }
`;

export default user;