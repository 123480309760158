import { gql } from "@apollo/client";

const datasetGradeEntries = gql`
  query getDatasetGradeEntries($datasetId: String!, $grader: Grader, $gradeType: String!, $parseId: Int, $variant: String, $weeksBack: Int) {
    datasetGradeEntries(datasetId: $datasetId, grader: $grader, gradeType: $gradeType, parseId: $parseId, variant: $variant, weeksBack: $weeksBack) {
      formattedName
      id
      value
    }
  }
`;

export default datasetGradeEntries;