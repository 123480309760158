import { gql } from "@apollo/client";

const pokemonGradeEntries = gql`
  query getPokemonGradeEntries($grader: Grader, $gradeType: String!, $parseId: Int, $pokemonId: Int!, $weeksBack: Int) {
    pokemonGradeEntries(grader: $grader, gradeType: $gradeType, parseId: $parseId, pokemonId: $pokemonId, weeksBack: $weeksBack) {
      dataset {
        id
        language
        formattedName
      }
      formattedName
      id
      value
    }
  }
`;

export default pokemonGradeEntries;