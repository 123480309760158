import React from 'react';
import { User, UserAccess, UserStatus } from '../types/user';

const UserContext = React.createContext<{
  signOut: () => void,
  updateUserAccess: (access: UserAccess) => void,
  updateUserStatus: (status: UserStatus) => void,
  user: User
}>({
  signOut: () => {},
  updateUserAccess: () => {},
  updateUserStatus: () => {},
  user: {
    access: UserAccess.None,
    status: UserStatus.NotReady,
  },
});

export default UserContext;