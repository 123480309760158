import React from 'react';
import { Avatar as MUIAvatar, AvatarProps } from '@mui/material';
import UserContext from '../../context/user';
import { UserStatus } from '../../types/user';

const Avatar: React.FC<AvatarProps> = ({ sx }) => {
  const { user } = React.useContext(UserContext);

  if (!user.data || user.status !== UserStatus.Online) {
    return null;
  }

  const {
    data: {
      displayName,
      photoURL,
    },
  } = user;

  return (
    <MUIAvatar
      alt={displayName ?? undefined}
      src={photoURL ?? undefined}
      sx={sx}
    />
  );
}

export default Avatar;