import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import { CircularProgress, Link, SxProps, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import UserContext from '../../context/user';
import { UserStatus } from '../../types/user';
import Avatar from '../user/Avatar';
import { Path } from '../../types/routes';
import DarkModeContext from '../../context/darkMode';

const User = () => {
  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down('sm'));

  const { isDarkModeEnabled } = React.useContext(DarkModeContext);
  const {
    signOut,
    user,
  } = React.useContext(UserContext);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<EventTarget & HTMLButtonElement>();

  if (user.status === UserStatus.NotReady) {
    return (
      <CircularProgress color="inherit" />
    );
  }

  const avatarStyle: SxProps<Theme> = {
    boxShadow: (theme) => `1px 1px 5px 0px ${theme.palette.grey[isDarkModeEnabled ? 300 : 700]}`,
    height: isMobileDisplay ? 32 : 40,
    width: isMobileDisplay ? 32 : 40,
  }

  if (user.status === UserStatus.Guest) {
    return (
      <Link
        component={ReactRouterLink}
        to="/login"
        sx={{
          color: (theme) => theme.palette.common.white,
        }}
      >
        <LoginTwoToneIcon sx={{
          height: 32,
          mr: isMobileDisplay ? undefined : 1,
          verticalAlign: 'middle',
          width: 32,
        }} />
        {isMobileDisplay ? undefined : 'Sign in'}
      </Link>
    );
  }

  const onAccountIconClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Link
        component={ReactRouterLink}
        to="/account"
      >
        <Avatar sx={avatarStyle} />
      </Link>
    </>
  )
}

export default User;