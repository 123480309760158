import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import DarkModeContext from '../../context/darkMode';

interface PageProps {
  sx?: SxProps<Theme>
}

const Page: React.FC<PageProps> = ({ children, sx }) => {
  const { isDarkModeEnabled } = React.useContext(DarkModeContext);

  return (
    <Box sx={{
      background: (theme) => theme.palette.grey[isDarkModeEnabled ? '800' : '100'],
      boxSizing: 'border-box',
      marginBottom: 0,
      minHeight: '100vh',
      position: 'relative',
      ...sx,
    }}>
      {children}
    </Box>
  )
}

export default Page;