import React from 'react';
import { Grader } from '../context/grader';
import CGCLogo from '../assets/cgc-logo.png';
import PSALogo from '../assets/psa-logo.png';

interface GraderLogoProps {
  grader: Grader;
  size?: "regular" | "large"
}

const getGraderLogoFromGrader: (grader: Grader) => string = (grader) => {
  switch (grader) {
    case Grader.CGC:
      return CGCLogo;

    case Grader.PSA:
      return PSALogo;
  }
}

const GraderLogo: React.FC<GraderLogoProps> = ({
  grader,
  size = "regular",
}) => {
  const logo = getGraderLogoFromGrader(grader);

  return (
    <img
      src={logo}
      alt={grader}
      height={size === "regular" ? "24px" : "32px"}
    />
  );
}

export default GraderLogo;