import { gql } from "@apollo/client";

const gradeHistory = gql`
  query getGradeHistory($grader: Grader, $parseId: Int) {
    gradeHistory(grader: $grader, parseId: $parseId) {
      date
      histogram {
        data {
          type
          value
        }
        date
        weeksBack
      }
      history {
        total {
          type
          value
        }
        weeksBack
        weeksCappedAt
      }
      total {
        type
        value
      }
      uniqueDatasets
      uniqueDatasetEntries
    }
  }
`;

export default gradeHistory;